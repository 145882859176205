<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="addroles">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="roleslist" border style="width: 100%;">
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column
        prop="name"
        :label="$t('xt.mingcheng')"
      ></el-table-column>
      <el-table-column :label="$t('caozuo')" width="150px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加角色弹窗区域 -->
    <el-dialog
      :title="$t('xinzeng')"
      :visible="adddialogvisible"
      :before-close="addClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form :model="addform" label-width="90px" ref="addref" :rules="rules">
        <el-form-item :label="$t('xt.mingcheng')" prop="name">
          <el-input v-model="addform.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('xt.quanxian')" prop="roles">
          <el-transfer
            v-model="addform.roles"
            filterable
            :right-default-checked="[]"
            :titles="[$t('xt.qxlb'), $t('xt.yyqx')]"
            :button-texts="[$t('xt.scqx'), $t('xt.tjqx')]"
            :format="{
              noChecked: '${total}',
              hasChecked: '${checked}/${total}'
            }"
            @change="handleChange"
            :data="rolesdata"
          >
            <span slot-scope="{ option }"
              >{{ option.id }} - {{ option.label }}</span
            >
          </el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addsubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="addClose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑角色弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="addform"
        label-width="150px"
        ref="editref"
        :rules="rules"
      >
        <el-form-item :label="$t('xt.mingcheng')" prop="name">
          <el-input v-model="addform.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('xt.quanxian')" prop="roles">
          <el-transfer
            v-model="addform.roles"
            filterable
            :right-default-checked="[]"
            :titles="[$t('xt.qxlb'), $t('xt.yyqx')]"
            :button-texts="[$t('xt.scqx'), $t('xt.tjqx')]"
            :format="{
              noChecked: '${total}',
              hasChecked: '${checked}/${total}'
            }"
            @change="handleChange"
            :data="rolesdata"
          >
            <span slot-scope="{ option }"
              >{{ option.id }} - {{ option.label }}</span
            >
          </el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      roleslist: [],
      total: 0,
      loading: true,
      // 权限信息
      rolesdata: [],
      // 添加角色数据对象
      addform: {
        name: '',
        roles: []
      },
      // 编辑的id
      editid: '',
      adddialogvisible: false,
      rules: {
        name: [{ required: true, message: '请填写角色名称', trigger: 'blur' }]
      },
      editdialogvisible: false
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      this.loading = true
      const { data } = await this.$http.get('/admin/system/rolelist', {
        params: this.queryinfo
      })
      this.roleslist = data.data.list
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 添加角色弹窗
    async addroles() {
      this.adddialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/system/addrole')
      if (data) {
        if (data.code === 200) {
          this.rolesdata = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 关闭添加弹窗
    addClose() {
      this.$refs.addref.resetFields()
      this.adddialogvisible = false
    },
    // 提交添加角色
    addsubmit() {
      this.$refs.addref.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/system/addrole',
          this.addform
        )
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.addClose()
            this.getlist()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/system/editrole/id/' + id)
      this.rolesdata = data.data.all
      this.addform.name = data.data.info.name
      this.addform.roles = data.data.info.rules
      this.editid = data.data.info.id
      loading.close()
    },
    // 编辑提交
    editsubmit() {
      this.$refs.editref.validate(async valid => {
        if (!valid) return false
        this.addform.id = this.editid
        const { data } = await this.$http.post(
          '/admin/system/editrole',
          this.addform
        )
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.editclose()
            this.getlist()
            this.editdialogvisible = false
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 关闭编辑弹窗
    editclose() {
      this.$refs.editref.resetFields()
      this.editdialogvisible = false
    },
    // handleChange
    handleChange() {}
  }
}
</script>

<style lang="less" scoped>
.el-pagination {
  margin-top: 15px;
  text-align: center;
}
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.el-select {
  min-width: 300px;
}
// 穿梭样式
/deep/ .el-transfer__buttons .el-button + .el-button {
  margin: 0;
}
/deep/ .el-transfer__buttons {
  .el-transfer__button {
    display: block;
  }
}
/deep/ .el-button [class*='el-icon-'] + span {
  margin: 0;
}
</style>
