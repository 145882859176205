var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',[_c('el-button',{on:{"click":_vm.addroles}},[_vm._v(_vm._s(_vm.$t('xinzeng')))])],1),_c('el-form-item',[_c('el-input',{staticStyle:{"width":"240px"},attrs:{"clearable":true},on:{"clear":_vm.getlist},model:{value:(_vm.queryinfo.query),callback:function ($$v) {_vm.$set(_vm.queryinfo, "query", $$v)},expression:"queryinfo.query"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.getlist},slot:"append"})],1)],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.roleslist,"border":""}},[_c('el-table-column',{attrs:{"prop":"id","label":"#"}}),_c('el-table-column',{attrs:{"prop":"name","label":_vm.$t('xt.mingcheng')}}),_c('el-table-column',{attrs:{"label":_vm.$t('caozuo'),"width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"content":_vm.$t('bianji'),"placement":"top","enterable":false}},[_c('i',{staticClass:"iconfont icon-bianji",on:{"click":function($event){return _vm.edit(row.id)}}})])]}}])})],1),_c('el-pagination',{attrs:{"hide-on-single-page":_vm.total <= 10,"background":"","layout":"prev, pager, next, total","page-size":10,"total":_vm.total,"current-page":_vm.queryinfo.page},on:{"current-change":_vm.changepage}}),_c('el-dialog',{attrs:{"title":_vm.$t('xinzeng'),"visible":_vm.adddialogvisible,"before-close":_vm.addClose,"close-on-click-modal":false,"close-on-press-escape":false}},[_c('el-form',{ref:"addref",attrs:{"model":_vm.addform,"label-width":"90px","rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":_vm.$t('xt.mingcheng'),"prop":"name"}},[_c('el-input',{model:{value:(_vm.addform.name),callback:function ($$v) {_vm.$set(_vm.addform, "name", $$v)},expression:"addform.name"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('xt.quanxian'),"prop":"roles"}},[_c('el-transfer',{attrs:{"filterable":"","right-default-checked":[],"titles":[_vm.$t('xt.qxlb'), _vm.$t('xt.yyqx')],"button-texts":[_vm.$t('xt.scqx'), _vm.$t('xt.tjqx')],"format":{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}'
          },"data":_vm.rolesdata},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var option = ref.option;
return _c('span',{},[_vm._v(_vm._s(option.id)+" - "+_vm._s(option.label))])}}]),model:{value:(_vm.addform.roles),callback:function ($$v) {_vm.$set(_vm.addform, "roles", $$v)},expression:"addform.roles"}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addsubmit}},[_vm._v(_vm._s(_vm.$t('tijiao')))]),_c('el-button',{on:{"click":_vm.addClose}},[_vm._v(_vm._s(_vm.$t('quxiao')))])],1)],1),_c('el-dialog',{attrs:{"title":_vm.$t('bianji'),"visible":_vm.editdialogvisible,"before-close":_vm.editclose,"close-on-click-modal":false,"close-on-press-escape":false}},[_c('el-form',{ref:"editref",attrs:{"model":_vm.addform,"label-width":"150px","rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":_vm.$t('xt.mingcheng'),"prop":"name"}},[_c('el-input',{model:{value:(_vm.addform.name),callback:function ($$v) {_vm.$set(_vm.addform, "name", $$v)},expression:"addform.name"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('xt.quanxian'),"prop":"roles"}},[_c('el-transfer',{attrs:{"filterable":"","right-default-checked":[],"titles":[_vm.$t('xt.qxlb'), _vm.$t('xt.yyqx')],"button-texts":[_vm.$t('xt.scqx'), _vm.$t('xt.tjqx')],"format":{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}'
          },"data":_vm.rolesdata},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var option = ref.option;
return _c('span',{},[_vm._v(_vm._s(option.id)+" - "+_vm._s(option.label))])}}]),model:{value:(_vm.addform.roles),callback:function ($$v) {_vm.$set(_vm.addform, "roles", $$v)},expression:"addform.roles"}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.editsubmit}},[_vm._v(_vm._s(_vm.$t('tijiao')))]),_c('el-button',{on:{"click":_vm.editclose}},[_vm._v(_vm._s(_vm.$t('quxiao')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }